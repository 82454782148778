import NextLink from 'next/link'
import { type ReactNode } from 'react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityThemeSettings,
  type SanityPrivacySettings,
} from '@data/sanity/queries/types/settings'
import { getPrivacyPolicyUrl } from '@lib/routes'

import CookieBar from './cookie-bar'
import Photo from './photo'
import Seo from './seo'

interface LayoutProps {
  brandName?: string
  headerPhoto?: SanityImageFragment
  draftMode?: boolean
  privacySettings: SanityPrivacySettings
  theme?: SanityThemeSettings
  children: ReactNode
}

const DraftModeOverlay = () => (
  <div className="sticky bottom-0 inset-x-0 z-90 bg-pageText bg-opacity-95">
    <div className="flex justify-between items-center container py-1">
      <p className="m-0 text-sm">Preview mode is active.</p>
      <NextLink href="/api/disable-draft" prefetch={false}>
        <button className="underline decoration-1 hover:decoration-2 text-pageBackground text-sm">
          Exit preview mode
        </button>
      </NextLink>
    </div>
  </div>
)

const Layout = ({
  brandName,
  headerPhoto,
  draftMode,
  privacySettings,
  theme,
  children,
}: LayoutProps) => {
  const {
    cookieConsentEnabled,
    cookieConsentMessage,
    customPrivacyPolicy,
    customPrivacyPolicyUrl,
  } = privacySettings
  const privacyPolicyUrl = getPrivacyPolicyUrl(
    customPrivacyPolicy,
    customPrivacyPolicyUrl,
  )

  return (
    <>
      <Seo brandName={brandName} headerPhoto={headerPhoto} theme={theme} />

      <div className="flex justify-center min-h-[100vh] sm:py-16 bg-pageText">
        {!!headerPhoto && (
          <Photo
            image={headerPhoto}
            className="fixed inset-0 pointer-events-none"
            imageClassName="w-full h-full object-cover opacity-80 blur-3xl"
          />
        )}

        <div className="relative w-full max-w-md">{children}</div>
      </div>

      {!!cookieConsentEnabled && (
        <CookieBar
          cookieConsentMessage={cookieConsentMessage}
          link={privacyPolicyUrl}
        />
      )}

      {!!draftMode && <DraftModeOverlay />}
    </>
  )
}

export default Layout
