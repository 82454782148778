import builder from '@sanity/image-url'
import {
  type ImageFormat,
  type SanityImageSource,
} from '@sanity/image-url/lib/types/types'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { getSanityClient } from './sanity/client'
import { parseJson } from './helpers'

export interface SourceOptionsProps {
  width?: number
  height?: number
  format?: ImageFormat
  quality?: number
  fitMax?: boolean
  autoFormat?: boolean
}

export interface ImageDimensions {
  width: number
  height: number
}

export const getImageFromSource = (source: string) => {
  const parsedSource = parseJson(source)

  if (parsedSource?.asset) {
    return parsedSource as unknown as SanityImageFragment
  }

  if (source.includes('://')) {
    return {
      asset: {
        url: source,
      },
    }
  }

  return {
    asset: {
      _ref: source,
    },
  }
}

/**
 * Builds image source URL from image data and options.
 */
export function getSanityImageUrl(
  image?: SanityImageSource,
  {
    width,
    height,
    format,
    quality,
    fitMax,
    autoFormat,
  }: SourceOptionsProps = {},
) {
  if (!image) {
    return
  }

  try {
    const sanityClient = getSanityClient()
    let imageUrlBuilder = builder(sanityClient).image(image)

    if (width) {
      imageUrlBuilder = imageUrlBuilder.width(Math.round(width))
    }

    if (height) {
      imageUrlBuilder = imageUrlBuilder.height(Math.round(height))
    }

    if (format) {
      imageUrlBuilder = imageUrlBuilder.format(format)
    }

    if (quality) {
      imageUrlBuilder = imageUrlBuilder.quality(quality)
    }

    if (fitMax) {
      imageUrlBuilder = imageUrlBuilder.fit('max')
    }

    if (autoFormat) {
      imageUrlBuilder = imageUrlBuilder.auto('format')
    }

    return imageUrlBuilder.url()
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gets image dimensions from one of the following sources:
 * - component props,
 * - fixed height field,
 * - image metadata dimensions and custom aspect ratio field,
 * - image metadata dimensions
 */
export const getImageDimensions = (
  image: SanityImageFragment,
  width?: number,
  height?: number,
) => {
  // Component props
  if (typeof width !== 'undefined' && typeof height !== 'undefined') {
    return {
      width,
      height,
    }
  }

  // Image metadata dimensions
  if (image.asset?.metadata?.dimensions) {
    return {
      width: image.asset.metadata.dimensions.width,
      height: image.asset.metadata.dimensions.height,
    }
  }
}
