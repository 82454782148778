import Head from 'next/head'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { getSanityImageUrl } from '@lib/image'
import { SanityThemeSettings } from '@data/sanity/queries/types/settings'

interface SeoProps {
  brandName?: string
  headerPhoto?: SanityImageFragment
  theme?: SanityThemeSettings
}

const Seo = ({ brandName, headerPhoto, theme }: SeoProps) => {
  const siteTitle = 'Minizite'
  const metaTitle = brandName
  const shareTitle = brandName
  const shareGraphicUrl = getSanityImageUrl(headerPhoto, {
    width: 1200,
    height: 630,
  })

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color={theme?.color}
      />
      <meta name="msapplication-TileColor" content={theme?.color} />
      <meta name="theme-color" content={theme?.color} />

      <link
        rel="preconnect"
        href="https://cdn.sanity.io"
        crossOrigin="anonymous"
      />

      <title>{metaTitle}</title>
      <meta name="title" property="og:title" content={shareTitle} />
      <meta name="twitter:title" content={shareTitle} />

      {shareGraphicUrl && (
        <>
          <meta name="image" property="og:image" content={shareGraphicUrl} />
          <meta name="twitter:image" content={shareGraphicUrl} />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:site_name" content={siteTitle} />
    </Head>
  )
}

export default Seo
