import { AnimatePresence, motion } from 'framer-motion'
import Cookies from 'js-cookie'
import NextLink from 'next/link'
import { Fragment, useEffect, useRef, useState } from 'react'

import { type SanityPrivacySettings } from '@data/sanity/queries/types/settings'
import { barAnimation } from '@lib/animate'

interface AcceptCookies {
  acceptedCookies: boolean
  onAcceptCookies: () => void
}

type CookieBarProps = Pick<SanityPrivacySettings, 'cookieConsentMessage'> & {
  link: string
}

const cookieName = 'accept_cookies'

const useAcceptCookies = (cookieName: string): AcceptCookies => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }
  }, [cookieName])

  return {
    acceptedCookies,
    onAcceptCookies: () => {
      setAcceptedCookies(true)
      Cookies.set(cookieName, 'accepted', {
        expires: 365,
      })
    },
  }
}

const CookieBar = ({ cookieConsentMessage, link }: CookieBarProps) => {
  const isFirstRender = useRef(true)

  const { acceptedCookies, onAcceptCookies } = useAcceptCookies(cookieName)

  if (isFirstRender.current || !cookieConsentMessage || acceptedCookies) {
    isFirstRender.current = false
    return null
  }

  return (
    <AnimatePresence>
      <motion.div
        initial="show"
        animate="show"
        exit="hide"
        variants={barAnimation}
        role="dialog"
        aria-live="polite"
        className="fixed bottom-0 right-0 z-90 p-4 w-full max-w-3xl"
      >
        <div className="grid gap-6 sm:flex items-center p-6 pb-4 sm:p-6 rounded-lg bg-pageBackground text-pageText">
          <div className="flex-1 sm:pr-8 text-center sm:text-left">
            <p className="text-sm leading-normal">
              {cookieConsentMessage
                .split('\n')
                .map((text: string, index: number) => (
                  <Fragment key={index}>
                    {text}
                    {!!cookieConsentMessage.split('\n')[index + 1] && <br />}
                  </Fragment>
                ))}
            </p>
          </div>

          <div className="flex justify-center flex-wrap items-center gap-x-8 gap-y-4">
            <NextLink href={link} className="underline">
              Learn more
            </NextLink>

            <button
              onClick={() => onAcceptCookies()}
              className="px-6 py-3 rounded-full font-medium border-white bg-pageText text-pageBackground hover:opacity-80"
            >
              Accept
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default CookieBar
