import cx from 'classnames'

import { type SanityPrivacySettings } from '@data/sanity/queries/types/settings'
import { getPrivacyPolicyUrl } from '@lib/routes'

import Icon from './icon'

type FooterProps = Pick<
  SanityPrivacySettings,
  'customPrivacyPolicy' | 'customPrivacyPolicyUrl'
> & {
  className?: string
}

const Footer = ({
  customPrivacyPolicy,
  customPrivacyPolicyUrl,
  className,
}: FooterProps) => {
  const privacyPolicyUrl = getPrivacyPolicyUrl(
    customPrivacyPolicy,
    customPrivacyPolicyUrl,
  )

  return (
    <footer className={cx('flex items-end gap-1 pt-5 pb-6 px-5', className)}>
      <a href={privacyPolicyUrl} className="basis-full text-xs">
        Privacy Policy
      </a>

      <span className="basis-full text-center">
        <a
          href="https://nethart.com"
          target="_blank"
          className="inline-flex flex-col items-center gap-4"
        >
          <Icon
            id="nethart-footer-logo"
            name="Nethart"
            className="w-8 h-8 text-pageText"
          />
          <span className="text-xs opacity-60">Made by Nethart</span>
        </a>
      </span>

      <span className="basis-full"></span>
    </footer>
  )
}

export default Footer
